import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToInstantWinCampaign2 = ({ location }) => {
  const title =
    "初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2"
          pagedesc="販促につながるTwitterインスタントウィンキャンペーンを開催するまでの手順を自社の実例と共に紹介します。実施に必要なチェックリスト付きで、すぐに使えるキャンペーン設計のコツが分かります。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-2/how-to-instant-win-campaign-2.jpg"
          pagepath="/blog/how-to-instant-win-campaign-2"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月19日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ToDoリストが書かれたノート"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-2/how-to-instant-win-campaign-2.jpg"
                className="w-full"
              />
            </figure>
            <div>
              <p>
                認知度アップやECサイトへの集客に大きな効果が見込める、「Twitterインスタントウィンキャンペーン」。気にはなるけど、具体的にどうやって始めればいいか分からないとお困りのマーケ担当者も多いようです。
              </p>
              <p>
                この記事では、PARKLoTが開催したキャンペーンを例に、Twitterインスタントウィン開催のために必要な4ステップのうち、ステップ3、ステップ4を解説します。
              </p>
              <p>ステップ1・2を未読の方はこちらから先にお読みください。</p>
              <div className="blog__border-highlight">
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </p>
              </div>
              <p>
                <br />
                この記事は次のような人にオススメです！
              </p>
              <ul className="blog__border-list">
                <li>Twitterインスタントウィンを初めて開催したい</li>
                <li>きちんと販促につながるTwitterキャンペーンがやりたい</li>
                <li>キャンペーンツールの導入を検討している</li>
              </ul>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    ステップ3：応募の方法と結果通知は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    ステップ4：キャンペーンツイートはどんな投稿？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    開催〜振り返り
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    まとめ
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    チェックリスト
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">ステップ3：応募の方法と結果通知は？</h2>
              <ul className="blog__border-list">
                <p>例）</p>
                <li>
                  キャンペーン名：ハロウィンキャンペーン第2弾！アマゾンギフト券大量プレゼント！
                </li>
                <li>参加方法：フォロー＆リツイート</li>
                <li>
                  結果通知：当選者のみ、即時自動DM送信（インスタントウィン）
                </li>
                <li>当選DM：下記画像参照</li>
                <li>実施期間：10月18日7:10 〜10月24日23:59 の7日間</li>
                <li>
                  応募要項：
                  <a
                    href="https://goodluck.park-lot.com/campaigns/1297"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://goodluck.park-lot.com/campaigns/1297
                  </a>
                </li>
                <li>
                  プライバシーポリシー：
                  <a
                    href="https://www.park-lot.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.park-lot.com/privacy
                  </a>
                </li>
                <li>
                  お問い合わせ先メールアドレス：
                  <a href="mailto:cs@park-lot.com">cs@park-lot.com</a>
                </li>
              </ul>
              <h3>＜キャンペーン名＞</h3>
              <p>
                PARKLoTでキャンペーンを開催する場合、
                <a
                  href="https://goodluck.park-lot.com/campaigns/1097"
                  target="_blank"
                  rel="noreferrer"
                >
                  自動で詳細ページが作成されます
                </a>
                。そこに表示されるキャンペーン名を設定しましょう。「クリスマスキャンペーン」「フォロワー3万人突破記念キャンペーン」など、イベントと絡めたりストーリー性のある設計だと、さらにエンゲージメントが期待できます。
              </p>
              <h3>＜参加方法＞</h3>
              <p>できるだけ、参加者が簡単に応募できるようにしましょう。</p>
              <p>今回は、</p>
              <ol>
                <li>PARKLoT公式アカウントのフォロー</li>
                <li>キャンペーンツイートのリツイート</li>
              </ol>
              <p>で応募が完了する設計にしました。</p>
              <h3>＜結果通知＞</h3>
              <p>結果通知については、</p>
              <ul>
                <li>タイミング（応募すぐか、〆切後か）</li>
                <li>手段（自動DM、手動送付DM、リプライ、その他）</li>
              </ul>
              <p>を、必ず「作業コスト」と併せて計画しましょう。</p>
              <p>
                今回は、PARKLoTの「デジタルコードDM自動送付機能」を使いました。この機能を使えば、参加者が応募すると、すぐに自動でDMが送られます。DM内には、事前にCSVファイルで共有したデジタルコードが挿入されています。応募したその場で当たりがわかり、当たった場合すぐに景品がゲットできるので、参加者にとってワクワクうれしい設定です。それだけでなく、事前にギフト券を購入してセットしておくだけなので、作業コストも大幅に削減できるため、開催者にもうれしい設定となっています。（作業負担増加・ミス防止の観点から、当選30名前後が、自動DMツールを導入する目安です。）
              </p>
              <p>
                また、ここで気をつけたいのがTwitterのDM送信上限（1,000件/日）・リプライ送信上限（2,400件/日ツイート・リツイート含む）です。上限を超える予想がされる場合は、対応が必要です。しかし、今回のように当選者100名にのみDMを送る設定であれば、問題ありません。
              </p>
              <h3>＜当選DM＞</h3>
              <div className="blog__border-highlight">
                【当選者名がここに表示されます】様
                <br />
                <br />
                キャンペーンにご応募ありがとうございます
                <span className="emoji">&#11088;</span>
                <span className="emoji">&#11088;</span>
                <br />
                【プレゼント名がここに表示されます】に当選しました
                <span className="emoji">&#127881;</span>
                <span className="emoji">&#127881;</span>
                <br />
                <br />
                <span className="emoji">&#128071;</span>
                <span className="emoji">&#128071;</span>
                あなたのコード
                <span className="emoji">&#128071;</span>
                <span className="emoji">&#128071;</span>
                <br />
                ━━━━━━━━━━━━━━━━━━━━
                <br />
                【デジタルコードがここに表示されます】
                <br />
                ━━━━━━━━━━━━━━━━━━━━
                <br />
                PARKLoTでは今後もキャンペーンを開催しています！
                <br />
                毎日チェックして、ぜひ応募してくださいね
                <span className="emoji">&#127919;</span>
                <br />
                <br />
                その他のキャンペーンも開催中です！
                <br />
                →→{" "}
                <a
                  href="https://goodluck.park-lot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://goodluck.park-lot.com/
                </a>
              </div>
              <img
                placeholder="tracedSVG"
                alt="Amazonギフト券100円分のあたりDM文言"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-2/how-to-instant-win-campaign-2-2.png"
              />
              <p>
                「デジタルコードDM自動送信機能」では、デジタルコードより上部の文言はデフォルトです。開催者からのメッセージは、デジタルコードより下部に載せることができます。自社サイトやEC、LPへの誘導も可能です。
              </p>
              <p>
                今回は、継続したキャンペーンへの参加と、懸賞ポータルサイト「PARKLoT」のURLを掲載しました。
              </p>
              <h3>＜実施期間＞</h3>
              <p>今回は、連続した7日間としました。</p>
              <p>
                最初は、3日〜7日間での実施がおすすめです。実施後、反応を確認してから次回開催の期間を検討しましょう。
              </p>
              <h3>＜応募要項＞</h3>
              <p>今回は、PARKLoTのデフォルトページを利用しました。</p>
              <h3>＜プライバシーポリシー＞</h3>
              <p>こちらも、今回はPARKLoTのデフォルトページを利用しました。</p>
              <h3>＜お問い合わせ先メールアドレス＞</h3>
              <p>自社のお問い合わせ用メールアドレスを設定しました。</p>
            </div>
            <div>
              <h2 id="id2">ステップ4：キャンペーンツイートはどんな投稿？</h2>
              <div className="blog__border-highlight">
                <p>
                  例）
                  <br />
                  <br />
                  ／
                  <br />
                  PARKLoT<span className="emoji">&#127875;</span>
                  <br />
                  #ハロウィンキャンペーン
                  <br />
                  第2弾！
                  <br />
                  ＼
                  <br />
                  <br />
                  #フォローRT でその場で当たる！
                  <br />
                  #Amazonギフト券 を100名様にプレゼント
                  <span className="emoji">&#128171;</span>
                  <br />
                  <br />
                  今すぐリツイートしてね！
                  <br />
                  <br />
                  <br />
                  【応募方法】
                  <br />
                  <span className="emoji">&#49;&#65039;&#8419;</span>@PARKLoT1
                  をフォロー
                  <br />
                  <span className="emoji">&#50;&#65039;&#8419;</span>
                  この投稿をRT
                  <br />
                  <span className="emoji">&#128233; </span>当選者のみDM送付
                  <br />
                  〆切　10/24
                </p>
              </div>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoTハロウィンキャンペーンバナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-2/how-to-instant-win-campaign-2-3.png"
              />
              <h3>＜投稿本文＞</h3>
              <p>投稿には、以下を含めましょう。</p>
              <ul>
                <li>
                  （必須）応募方法
                  <p>・フォローするべきアカウントはどれか</p>
                  <p>・リツイートするべきツイートはどれか</p>
                </li>
                <li>（必須）〆切日</li>
                <li>
                  （必須）結果確認方法
                  <p>
                    ・PARKLoTでギフト券自動配布機能を使う場合、当選者にのみDMが送信されます。
                  </p>
                </li>
                <li>
                  （任意）ハッシュタグ
                  <p>
                    ・よく使われるハッシュタグなど、「次の文字との間に半角スペースを入れる」のを忘れずにつけて入力します。
                  </p>
                </li>
              </ul>
              <h3>＜バナー＞</h3>
              <p>
                バナークリエイティブの品質は、キャンペーンの盛り上がりに直結します。バナーは、サービスの雰囲気に合わせつつも、タイムライン上で目を引き、ユーザーが思わず参加したくなるようなデザインが理想です。今回は、プロのデザイナーさんに依頼して、ハロウィンの楽しさを表現し、かつ情報を読み取りやすいバナーを作成いただきました。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id3">開催〜振り返り</h2>
              <p>
                PARKLoTは自動投稿システムなので、管理画面からキャンペーンを作成した場合は、その後の作業はありません。しかし、ツイートが投稿されたらプロフィールに固定表示（ピン留め）する・定期的にリツイートするなどして、キャンペーンをさらに盛り上げましょう。PARKLoT公式アカウントでは、当選報告ツイートをいただくこともあります。「ご参加ありがとうございます！」の気持ちを込めて、リアクションをするのも大事です。
              </p>
              <p>
                開催期間が終わったら、KPIを確認します。結果をもとに今回のキャンペーンについて分析し、次回開催では改善できるようにしましょう。Twitterキャンペーンは、継続的に行うことでより効果が高まります。キャンペーン回数をこなしながら、販促目的につながるような企画を開催していくのがコツです。
              </p>
            </div>
            <div>
              <h2 id="id4">まとめ</h2>
              <p>
                各項目の説明とチェックリストを使えば、成果につながるTwitterフォロー＆リツイートキャンペーン（インスタントウィン）が企画できます。当選人数を減らし後日抽選にして手動で開催するなど、アレンジのきく内容になっていますので、貴社のTwitterアカウントでもぜひ実施してみてください。
              </p>
            </div>
            <div>
              <h2 id="id5">チェックリスト</h2>
              <p>
                インスタントウィン企画チェックリストをご用意しましたので、ぜひお使いください！
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="インスタントウィン企画チェックリスト2"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-2/how-to-instant-win-campaign-2-4.jpg"
              />
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/how-to-instant-win-campaign-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default HowToInstantWinCampaign2
